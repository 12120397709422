import kamigameLogo from '../img/kamigame_logo_color.png';
import treasure from '../img/treasure.jpeg';
import sunrise from '../img/sunrise.jpg';
import techvision from '../img/techvision.png';

export const problems = [
    { logo: kamigameLogo, problem: "神ゲー攻略でゲームマスターになろう", hiragana: "かみげーこうりゃくでげーむますたーになろう" },
    { logo: "https://cartaholdings.co.jp/wp-content/themes/cartaholdings/common/img/logo_carta-black.svg", problem: "挑戦しよう", hiragana: "ちょうせんしよう" },
    { logo: "https://cartaholdings.co.jp/wp-content/themes/cartaholdings/common/img/logo_carta-black.svg", problem: "スピードは力", hiragana: "すぴーどはちから" },
    { logo: "https://cartaholdings.co.jp/wp-content/themes/cartaholdings/common/img/logo_carta-black.svg", problem: "全誠実でいよう", hiragana: "ぜんせいじつでいよう" },
    { logo: "https://cartaholdings.co.jp/wp-content/themes/cartaholdings/common/img/logo_carta-black.svg", problem: "違いを強さに", hiragana: "ちがいをつよさに" },
    { logo: "https://cartaholdings.co.jp/wp-content/themes/cartaholdings/common/img/logo_carta-black.svg", problem: "越えよう", hiragana: "こえよう" },
    { logo: "https://cartaholdings.co.jp/wp-content/themes/cartaholdings/common/img/logo_carta-black.svg", problem: "いつだって楽しく", hiragana: "いつだってたのしく" },
    { logo: "https://cartaholdings.co.jp/wp-content/themes/cartaholdings/common/img/logo_carta-black.svg", problem: "進化推進業", hiragana: "しんかすいしんぎょう" },
    { logo: "https://cartaholdings.co.jp/wp-content/themes/cartaholdings/common/img/logo_carta-black.svg", problem: "東証プライムにいるよ！いえい！", hiragana: "とうしょうぷらいむにいるよ！いえい！" },
    { logo: "https://cartaholdings.co.jp/wp-content/themes/cartaholdings/common/img/logo_carta-black.svg", problem: "会長はうさぽんです", hiragana: "かいちょうはうさぽんです" },
    { logo: techvision, problem: "フルサイクル開発", hiragana: "ふるさいくるかいはつ" },
    { logo: techvision, problem: "フルサイクルエンジニア", hiragana: "ふるさいくるえんじにあ" },
    { logo: techvision, problem: "人にもっと創造的な仕事を", hiragana: "ひとにもっとそうぞうてきなしごとを" },
    { logo: techvision, problem: "本質志向", hiragana: "ほんしつしこう" },
    { logo: techvision, problem: "共に信頼し共に創る", hiragana: "ともにしんらいしともにつくる" },
    { logo: techvision, problem: "価値を届け続ける", hiragana: "かちをとどけつづける" },
    { logo: techvision, problem: "質は速さ", hiragana: "しつははやさ" },
    { logo: techvision, problem: "推測するな計測せよ", hiragana: "すいそくするなけいそくせよ" },
    { logo: techvision, problem: "先人に感謝し還元する", hiragana: "せんじんにかんしゃしかんげんする" },
    { logo: techvision, problem: "毎日試す", hiragana: "まいにちためす" },
    { logo: techvision, problem: "最良のコードはコードなし", hiragana: "さいりょうのこーどはこーどなし" },
    { logo: "https://talent.supporterz.jp/img/logo.png", problem: "サポーターズはCARTAのサービスです", hiragana: "さぽーたーずはCARTAのさーびすです" },
    { logo: "https://kotobank.jp/image/sp/logo_about.gif", problem: "コトバンクはCARTAのサービスです", hiragana: "ことばんくはCARTAのさーびすです" },
    { logo: "https://digi-co.net/bundles/vmdigicobiz/img/digico_logo.png", problem: "デジコはCARTAのサービスです", hiragana: "でじこはCARTAのさーびすです" },
    { logo: treasure, problem: "Treasure応募待ってます！", hiragana: "Treasureおうぼまってます！" },
    { logo: sunrise, problem: "Sunriseでインフラマスターになろう！", hiragana: "Sunriseでいんふらますたーになろう！" },
 ]