import {characters} from "../character/characters";
import {isUnableCharactersOnDoubleForSoundRepellent, isUnusableNForん} from "../character/rule";

export function createValidInputs(hiraganaInput) {
    const hiraganaList = hiraganaInput.split("");

    let inputPatterns = [];
    for (let i = 0; i < hiraganaList.length; i++) {
        const inputSinglePatterns = [];
        if (i < hiraganaList.length - 1 && hiraganaList[i] + hiraganaList[i + 1] in characters) {
            inputSinglePatterns.push(...(characters[hiraganaList[i] + hiraganaList[i + 1]]));
            characters[hiraganaList[i]].forEach((romans) => {
                characters[hiraganaList[i + 1]].forEach((romansNext) => {
                    inputSinglePatterns.push(romans + romansNext);
                });
            });
            i++;
        } else if (hiraganaList[i] === 'っ' && i < hiraganaList.length -1) {
            if (!isUnableCharactersOnDoubleForSoundRepellent(hiraganaList[i + 1])) {
                inputSinglePatterns.push(...characters[hiraganaList[i + 1]].map((romans) => romans[0] + romans));
            }

            characters[hiraganaList[i]].forEach((romans) => {
                characters[hiraganaList[i + 1]].forEach((romans2) => {
                    inputSinglePatterns.push(romans + romans2);
                });
            });
            i++;
        } else if (hiraganaList[i] === 'ん') {
            if(i === hiraganaList.length - 1 && isUnusableNForん(hiraganaList[i + 1])) {
                inputSinglePatterns.push(...characters[hiraganaList[i]].filter((romans) => romans !== 'n'));
            } else {
                inputSinglePatterns.push(...characters[hiraganaList[i]]);
            }
        } else {
            inputSinglePatterns.push(...characters[hiraganaList[i]]);
        }

        if (inputPatterns.length === 0) {
            inputPatterns = inputSinglePatterns
        } else {
            const newPatterns = [];
            inputPatterns.forEach((romans1) => {
                inputSinglePatterns.forEach((romans2) => {
                    newPatterns.push(romans1 + romans2);
                });
            });
            inputPatterns = newPatterns;
        }
    }

    return inputPatterns;
}