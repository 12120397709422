export const isUnableCharactersOnDoubleForSoundRepellent = (character) => {
  const unableCharactersOnDoubleForSoundRepellent = ['あ', 'い', 'う', 'え', 'お', 'ん', 'っ', 'な', 'に', 'ぬ', 'ね', 'の'];

  return unableCharactersOnDoubleForSoundRepellent.includes(character);
}

export const isUnusableNForん = (character) => {
  const unusableNForん = ['あ', 'い', 'う', 'え', 'お', 'ん', 'な', 'に', 'ぬ', 'ね', 'の'];

  return unusableNForん.includes(character);
};