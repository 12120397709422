export const characters = {
    あ: ['a'],
    い: ['i', 'yi'],
    う: ['u', 'whu', 'wu'],
    え: ['e'],
    お: ['o'],

    か: ['ka', 'ca'],
    き: ['ki'],
    く: ['ku', 'cu', 'qu'],
    け: ['ke'],
    こ: ['ko', 'co'],

    が: ['ga'],
    ぎ: ['gi'],
    ぐ: ['gu'],
    げ: ['ge'],
    ご: ['go'],

    さ: ['sa'],
    し: ['shi', 'si', 'ci'],
    す: ['su'],
    せ: ['se', 'ce'],
    そ: ['so'],

    ざ: ['za'],
    じ: ['ji', 'zi'],
    ず: ['zu'],
    ぜ: ['ze'],
    ぞ: ['zo'],

    た: ['ta'],
    ち: ['chi', 'ti'],
    つ: ['tsu', 'tu'],
    て: ['te'],
    と: ['to'],

    だ: ['da'],
    ぢ: ['di'],
    づ: ['du'],
    で: ['de'],
    ど: ['do'],

    な: ['na'],
    に: ['ni'],
    ぬ: ['nu'],
    ね: ['ne'],
    の: ['no'],

    は: ['ha'],
    ひ: ['hi'],
    ふ: ['fu', 'hu'],
    へ: ['he'],
    ほ: ['ho'],

    ば: ['ba'],
    び: ['bi'],
    ぶ: ['bu'],
    べ: ['be'],
    ぼ: ['bo'],

    ぱ: ['pa'],
    ぴ: ['pi'],
    ぷ: ['pu'],
    ぺ: ['pe'],
    ぽ: ['po'],

    ま: ['ma'],
    み: ['mi'],
    む: ['mu'],
    め: ['me'],
    も: ['mo'],

    や: ['ya'],
    ゆ: ['yu'],
    よ: ['yo'],

    ら: ['ra'],
    り: ['ri'],
    る: ['ru'],
    れ: ['re'],
    ろ: ['ro'],

    わ: ['wa'],
    を: ['wo'],
    ん: ['nn', 'n', 'xn'],

    ぁ: ['xa', 'la'],
    ぃ: ['xi', 'li', 'lyi', 'xyi'],
    ぅ: ['xu', 'lu'],
    ぇ: ['xe', 'le', 'lye', 'xye'],
    ぉ: ['xo', 'lo'],
    ゃ: ['xya', 'lya'],
    ゅ: ['xyu', 'lyu'],
    ょ: ['xyo', 'lyo'],
    っ: ['ltu', 'xtu', 'ltsu'],

    ゔ: ['vu'],
    ゔぁ: ['va'],
    ゔぃ: ['vi', 'vyi'],
    ゔぇ: ['ve', 'vye'],
    ゔぉ: ['vo'],
    ゔゃ: ['vya'],
    ゔゅ: ['vyu'],
    ゔょ: ['vyo'],

    きゃ: ['kya'],
    きぃ: ['kyi'],
    きゅ: ['kyu'],
    きぇ: ['kye'],
    きょ: ['kyo'],

    くゃ: ['qya'],
    くゅ: ['qyu'],
    くょ: ['qyo'],
    くぁ: ['qwa', 'qa', 'kwa'],
    くぃ: ['qwi', 'qi', 'qyi'],
    くぅ: ['qwu'],
    くぇ: ['qwe', 'qe', 'qye'],
    くぉ: ['qwo', 'qo'],

    ぎゃ: ['gya'],
    ぎぃ: ['gyi'],
    ぎゅ: ['gyu'],
    ぎぇ: ['gye'],
    ぎょ: ['gyo'],

    ぐぁ: ['gwa'],
    ぐぃ: ['gwi'],
    ぐぅ: ['gwu'],
    ぐぇ: ['gwe'],
    ぐぉ: ['gwo'],

    しゃ: ['sha', 'sya'],
    しぃ: ['syi'],
    しゅ: ['shu', 'syu'],
    しぇ: ['sye', 'she'],
    しょ: ['sho', 'syo'],

    すぁ: ['swa'],
    すぃ: ['swi'],
    すぅ: ['swu'],
    すぇ: ['sew'],
    すぉ: ['swo'],

    じゃ: ['ja', 'jya', 'zya'],
    じぃ: ['zyi', 'jyi'],
    じゅ: ['ju', 'jyu', 'zyu'],
    じぇ: ['zye', 'je', 'jye'],
    じょ: ['jo', 'jyo', 'zyo'],

    ちゃ: ['cha', 'cya', 'tya'],
    ちぃ: ['tyi', 'cyi'],
    ちゅ: ['chu', 'cyu', 'tyu'],
    ちぇ: ['tye', 'che', 'cye'],
    ちょ: ['cho', 'cyo', 'tyo'],

    ぢゃ: ['dya'],
    ぢぃ: ['dyi'],
    ぢゅ: ['dyu'],
    ぢぇ: ['dye'],
    ぢょ: ['dyo'],

    つぁ: ['tsa'],
    つぃ: ['tsi'],
    つぇ: ['tse'],
    つぉ: ['tso'],

    てゃ: ['tha'],
    てぃ: ['thi'],
    てゅ: ['thu'],
    てぇ: ['the'],
    てょ: ['tho'],

    でゃ: ['dha'],
    でぃ: ['dhi'],
    でゅ: ['dhu'],
    でぇ: ['dhe'],
    でょ: ['dyo'],

    とぁ: ['twa'],
    とぃ: ['twi'],
    とぅ: ['twu'],
    とぇ: ['twe'],
    とぉ: ['two'],

    どぁ: ['dwa'],
    どぃ: ['dwi'],
    どぅ: ['dwu'],
    どぇ: ['dwe'],
    どぉ: ['dwo'],

    にゃ: ['nya'],
    にぃ: ['nyi'],
    にゅ: ['nyu'],
    にぇ: ['nye'],
    にょ: ['nyo'],

    ひゃ: ['hya'],
    ひぃ: ['hyi'],
    ひゅ: ['hyu'],
    ひぇ: ['hye'],
    ひょ: ['hyo'],

    ふぁ: ['fwa', 'fa'],
    ふぃ: ['fwi', 'fi', 'fyi'],
    ふぅ: ['fwu'],
    ふぇ: ['fwe', 'fe', 'fye'],
    ふぉ: ['fwo', 'fo'],

    ふゃ: ['fya'],
    ふゅ: ['fyu'],
    ふょ: ['fyo'],

    びゃ: ['bya'],
    びぃ: ['byi'],
    びゅ: ['byu'],
    びぇ: ['bye'],
    びょ: ['byo'],

    ぴゃ: ['pya'],
    ぴぃ: ['pyi'],
    ぴゅ: ['pyu'],
    ぴぇ: ['pye'],
    ぴょ: ['pyo'],

    みゃ: ['mya'],
    みぃ: ['myi'],
    みゅ: ['myu'],
    みぇ: ['mye'],
    みょ: ['myo'],

    りゃ: ['rya'],
    りぃ: ['ryi'],
    りゅ: ['ryu'],
    りぇ: ['rye'],
    りょ: ['ryo'],

    うぁ: ['wha'],
    うぃ: ['wi', 'whi'],
    うぇ: ['we', 'whe'],
    うぉ: ['who'],

    ゎ: ['lwa', 'xwa'],

    ぃぇ: ['ye'],

    ー: ['-'],
    '、': [','],
    '。': ['.'],
    '！': ['!'],

    a: ['a'],
    b: ['b'],
    c: ['c'],
    d: ['d'],
    e: ['e'],
    f: ['f'],
    g: ['g'],
    h: ['h'],
    i: ['i'],
    j: ['j'],
    k: ['k'],
    l: ['l'],
    m: ['m'],
    n: ['n'],
    o: ['o'],
    p: ['p'],
    q: ['q'],
    r: ['r'],
    s: ['s'],
    t: ['t'],
    u: ['u'],
    v: ['v'],
    w: ['w'],
    x: ['x'],
    y: ['y'],
    z: ['z'],
    A: ['A'],
    B: ['B'],
    C: ['C'],
    D: ['D'],
    E: ['E'],
    F: ['F'],
    G: ['G'],
    H: ['H'],
    I: ['I'],
    J: ['J'],
    K: ['K'],
    L: ['L'],
    M: ['M'],
    N: ['N'],
    O: ['O'],
    P: ['P'],
    Q: ['Q'],
    R: ['R'],
    S: ['S'],
    T: ['T'],
    U: ['U'],
    V: ['V'],
    W: ['W'],
    X: ['X'],
    Y: ['Y'],
    Z: ['Z'],
};